import IMask from 'imask';

export const isClientSide = (): boolean => typeof window !== 'undefined';
export const isInitialSiteLoad = (url: string | undefined): boolean =>
  typeof url === 'string' && !/(^\/_next).+(\.json$)/.test(url);

export function formatDate(date: Date | string): string | null {
  if (date) {
    let formattedDate = new Date(date).toDateString().slice(4);
    const dateYear = formattedDate.slice(formattedDate.lastIndexOf(' '));
    formattedDate = formattedDate.replace(dateYear, `,${dateYear}`);
    return formattedDate;
  }
  return null;
}

export const colorExtractor = (color: string | undefined, defaultColor: string) => {
  return typeof color === 'string' ? color.substring(color.indexOf('#')) : defaultColor;
};

// Used to convert hex color code into hsl. You can adjust the lightness or darkness of the color that is passed in as the second parameter. Original use was for hover on buttons.
// hexColor: The color as a hex value
// amount: Is added to the current value of L. A negative value will subject from the lightness value.
export function LightenDarkenColor(hexColor: string, amount: number) {
  const H = hexColor;

  // Convert hex to RGB first
  let r: any = 0,
    g: any = 0,
    b: any = 0;
  if (H?.length == 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H?.length == 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin;
  let h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  const lightness = l + amount;

  return 'hsl(' + h + ',' + s + '%,' + lightness + '%)';
}

export const userIdMask = IMask.createMask({
  mask: '000-000-0000'
});

export const getMaskedUserIdValue = (value: string | undefined) => {
  if (!value) return value;

  userIdMask.resolve(value as string);

  return userIdMask.value;
};

export const phoneMask = IMask.createMask({
  mask: '(000) 000-0000'
});

export const getMaskedPhoneValue = (value: string | undefined) => {
  if (!value) return value;

  // Used to prevent cursor from changing positions when editing the field. It only formats the phone number when meeting these conditions
  const shouldReformat = (unformattedValue: string) =>
    /^[(]\d{3}[)][\s]\d{3}[-]\d{4}$/.test(unformattedValue) ||
    /^[(]\d{3}[)][\s]\d{4}$/.test(unformattedValue) ||
    /^[(]\d{3}[)][\s]$/.test(unformattedValue) ||
    /^[(]\d{4}$/.test(unformattedValue) ||
    /^\d+$/.test(unformattedValue);

  phoneMask.resolve(value as string);

  return shouldReformat(value) ? phoneMask.value : value;
};

export const cleanPhoneMask = IMask.createMask({
  mask: '(000) 000-0000'
});

export const getCleanMaskedPhoneValue = (value: string): string => {
  cleanPhoneMask.resolve(value as string);

  return cleanPhoneMask.value;
};

export const unMaskedPhone = IMask.createMask({
  mask: '0000000000'
});

export const unMaskPhoneValue = (value: string): string => {
  if (!value) return value;

  const cleanValue = value.replace('+1', '');

  unMaskedPhone.resolve(cleanValue as string);

  return unMaskedPhone.value;
};

export const toBase64 = (str: string) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

export const urlForwardSlashCleaner = (url: string | undefined) => {
  if (!url) return;

  return (url?.charAt(0) === '/' || url.startsWith('http') ? url : `/${url}`)
    .replace(/([^:]\/)\/+/g, '$1')
    .replace(/\\/g, '/');
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const dateRegexPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/g;

export const hexToRgbA = (hex: string, transparency?: string | number): string | null => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${transparency ?? 1})`
    );
  }

  throw new Error(`Bad Hex -  hex passed in: ${hex}`);
};

export const stringToCamelCase = (string?: string) => {
  if (!string) return undefined;

  return string
    .split(' ')
    ?.map((word, index) =>
      index === 0
        ? word?.charAt(0).toLowerCase() + word.slice(1)
        : word?.charAt(0).toUpperCase() + word.slice(1)
    )
    .join('');
};

export const capitalizeAllWordsInString = (string: string): string => {
  const words = string.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
};

// export const isChurnedOffer = (offerCode: string): boolean =>
//   (offerCode?.includes("$") && offerCode?.includes("CPN"))

export const isFrequencyOffer = (offerCode: string): boolean =>
  (offerCode?.slice(-1) === '!' || offerCode === 'CPNBSPEND1')

export const isLoadableOffer = (offerCode: string): boolean =>
  (offerCode?.substring(0, 3) === 'CPN')

export const isBirthdayOffer = (offerCode: string): boolean =>
  (offerCode?.includes('BDAY'))
